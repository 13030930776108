import axios from 'axios';
import qs from 'qs';
import SearchConfig from '@sun-chemical/system-config/search-config';
import { updateUrlByParams, parseUrlFilters } from 'utils/url';

// Setup api client
const apiClient = axios.create({
  baseURL: payload.services.searchservice.url,
  headers: {
    'request-region': payload.generic.requestRegion
  }
});

export default {
  /**
   * Search pigments based on the current location.search string.
   *
   * @param params
   * @param {string|null} token The users JWT for access level detection, only public infos will be visible without token
   * @param {object} data
   * @return array
   */
  async search(params, token = null, data = {}) {
    const { urlParams } = updateUrlByParams(params);

    let config = {};
    if (token) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }

    return (await apiClient.post(`/api/v1/pigment/search${urlParams}`, data, config)).data;
  },

  async searchWithoutUrlUpdate(params, token = null, body = {}) {
    const urlParams = qs.stringify(params, { arrayFormat: 'comma', encode: false });

    let config = {};
    if (token) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }

    return (await apiClient.post(`/api/v1/pigment/search?${urlParams}`, body, config)).data;
  },

  async loadMore(from, token = null) {
    let config = {};
    if (token) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }

    return (await apiClient.post(`/api/v1/pigment/search${location.search}`, { from }, config)).data;
  },

  /**
   * Get pigment suggestions based on the given search term.
   *
   * @param {String} term
   * @param {Object} activeFilters
   * @param {string|null} token The users JWT for access level detection, required to be able to see internal products
   * @return {Promise<any>}
   */
  suggest(term, activeFilters, token = null) {
    let params = {};
    if (activeFilters && Object.keys(activeFilters).length > 0) {
      params = { ...activeFilters };
    }

    params[SearchConfig.getMap().search_term.param] = encodeURIComponent(term);

    let config = {};
    if (token) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }

    return new Promise((resolve, reject) => {
      apiClient
        .get(`/api/v1/pigment/suggest?${qs.stringify(params, { arrayFormat: 'comma', encode: false })}`, config)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => reject(error));
    });
  },
  parseUrlFilters() {
    return parseUrlFilters();
  },

  /**
   * Get detail information for one pigment.
   *
   * @param {int} id The internal pigment id
   * @param {string} industry The industry to get the information for, affects the visibility
   * @param {string|null} token The users JWT for access level detection, only public infos will be visible without token
   */
  async getOne(id, industry, token = null) {
    let config = {};
    if (token) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }

    return (await apiClient.get(`/api/v1/pigment/${id}?i=${industry}`, config)).data;
  },

  /**
   * Fetch the detail information of multiple pigments at once via IDs.
   *
   * @param {[]} ids
   * @param {string} industry
   * @param {string} token
   * @param {boolean} withStackInfo
   * @return {Promise<[]>}
   */
  async fetchByIds(ids, industry, token = null, withStackInfo = false) {
    let config = {};
    if (token) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }

    let url = `/api/v1/pigment/byIds?ids=${ids.join(',')}&i=${industry}`;

    if (withStackInfo) {
      url = `${url}&type=imageStacks&stackFileVersion=2`;
    }

    const result = (await apiClient.get(url, config)).data;

    result.forEach((product) => {
      if (product[industry] && typeof product[industry] === 'object') {
        Object.keys(product[industry]).forEach((key) => {
          product[key] = product[industry][key];
        });

        delete product[industry];
      }
    });

    return result;
  }
};
